@charset "utf-8";

$emph-color: #800;

html {
  overflow-y: scroll;
}

body {
  padding-top: 70px;
}

code.tex {
  background-color: #f5f5f5;
}

article > h1.page-header {
  color: $emph-color;
  margin-top: 0;
}

#footer {
  margin-top: 1em;
}

#lt-twitter-btn {
  /* Differenz ist #D0D0C */
  background-color: #4099FF;
  border-color: #338CF3;
}

#lt-gplus-btn {
  /* Differenz ist #D0D0C */
  background-color: #d34836;
  border-color: #c63b2a;
}

#lt-toc > li {
  padding-bottom: 10px;
}

#lt-toc > li > a {
  color: #222;
  font-weight: 700;
  padding: 0;
}

#lt-toc > li.active > a {
  color: $emph-color;
}

.lt-toc-cat > li.active > a {
  text-decoration: underline;
}

.lt-toc-cat > li > a {
  color: #222;
  margin-left: 1em;
  padding: 2px 0;
}

.lt-toc-cat > li:first-child > a {
  padding: 4px 0 2px 0;
}

.lt-toc-cat > li:last-child > a {
  padding: 2px 0 4px 0;
}

.lt-toc-sub > li a {
  color: #777;
  margin-left: 1em;
  padding: 2px;
}

#lt-toc ul.lt-toc-sub > li a {
  margin-left: 2em;
  padding-left: 5px;
}

#lt-toc ul.lt-toc-sub > li.active a {
  border-left: 2px solid $emph-color;
  padding-left: 3px;
}

aside .addthis_toolbox {
  margin-top: 1em;
}

article img.pull-left {
	margin-right: 10px;
}

article img.pull-right {
	margin-left: 10px;
}
